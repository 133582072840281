import { ethers } from 'ethers'
import { getMulticallContract } from 'utils/contractHelpers'
import contracts from 'config/constants/contracts'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'config/constants/multicall'
import rpc from '../config/constants/rpcs'
import { MultiCallResponse } from './types'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  requireSuccess?: boolean
}

const multicall = async (abi: any[], calls: Call[]) => {
  try {
    const chainId = Number(contracts.networkId)
    const provider = new ethers.providers.JsonRpcProvider(rpc.rpc[chainId]);
    const multicallContract = new ethers.Contract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, provider)

    // const multicallContract = getMulticallContract()
    const itf = new ethers.utils.Interface(abi)
    // console.log(calls)
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])

    const { returnData } = await multicallContract.aggregate(calldata)
    const res = returnData.map((call, i) => {
      return itf.decodeFunctionResult(calls[i].name, call === "0x" ? "0x0000000000000000000000000000000000000000000000000000000000000000" : call)
    })
    return Promise.resolve(res)
  } catch (err) {
    console.log("multicall error", err)
    return null
  }
}

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return inclues a boolean whether the call was successful e.g. [wasSuccessfull, callResult]
 */
export const multicallv2 = async <T = any>(
  abi: any[],
  calls: Call[],
  options: MulticallOptions = { requireSuccess: true },
): Promise<MultiCallResponse<T>> => {
  const { requireSuccess } = options
  const multi = getMulticallContract()
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])

  const returnData = await multi.tryAggregate(requireSuccess, calldata)
  const res = returnData.map((call, i) => {
    const [result, data] = call
    return result ? itf.decodeFunctionResult(calls[i].name, data) : null
  })

  return res
}

export default multicall
