import { ChainId } from '@pancakeswap/sdk'

export default {
  networkId: (() => {
    return localStorage.getItem('CurrentChain') || process.env.REACT_APP_CHAIN_ID
  })(),
  router: {
    [ChainId.SEPOLIA]: '0x6AE963C19Ff1c74605578891fcFe901c2754766f',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_FACTORY,
    // [ChainId.BSC]: process.env.REACT_APP_FACTORY,
    [ChainId.BSC_TESTNET]: '0xE2F46B4A28c8c8282dE0898d8f16C229dcFcc6A0',
    // [ChainId.POLYGON]: process.env.REACT_APP_FACTORY,
    [ChainId.AMOY]: '0xAeE70548e412ddB1930F740d90FA1BED50B36a82',
  },
  factory: {
    [ChainId.SEPOLIA]: '0x685293B37A1176AcD559CeFAA30002dAD837aDD0',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_FACTORY,
    // [ChainId.BSC]: process.env.REACT_APP_FACTORY,
    [ChainId.BSC_TESTNET]: '0x41E99cdf5d3423072a6e419F6AA0efbfdA9162BE',
    // [ChainId.POLYGON]: process.env.REACT_APP_FACTORY,
    [ChainId.AMOY]: '0x259EB91022c05fB94D51b64B2c769a942a9351A4',
  },
  masterChef: {
    [ChainId.SEPOLIA]: '0x24fdD3129177cc55661E6dc6a51fCE52d7584566',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_MASTERCHEF,
    // [ChainId.BSC]: process.env.REACT_APP_MASTERCHEF,
    [ChainId.BSC_TESTNET]: '0xE6d9f1189c9A33b8D84Fc5A1a3F5c566E2735e91',
    // [ChainId.POLYGON]: process.env.REACT_APP_MASTERCHEF,
    [ChainId.AMOY]: '0x213B63094eF3FEB0e1E7836766f1d23f51197fA6',
  },
  sousChef: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: '',
  },
  lottery: {
    [ChainId.SEPOLIA]: '0xC0eF0A4578732600f071aC5029E22B61D195F8Cf',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_LOTTERY,
    // [ChainId.BSC]: process.env.REACT_APP_LOTTERY,
    [ChainId.BSC_TESTNET]: '0xcfEb9072322662A3F9Cde4FE2e30C09750eAf438',
    // [ChainId.POLYGON]: process.env.REACT_APP_LOTTERY,
    [ChainId.AMOY]: '0x204dbe0ef67818Bb5b6B337eb764f95B9FACfaB9',
  },
  lotteryTicket: {
    [ChainId.SEPOLIA]: '0x72804AFEcd68d6E39e76310e77A4409f81f8A7fC',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_LOTTERY,
    // [ChainId.BSC]: process.env.REACT_APP_LOTTERY,
    [ChainId.BSC_TESTNET]: '0xF7e431Fdd9FBf6E9d29a3CBDc037Cf6C80b39DD6',
    // [ChainId.POLYGON]: process.env.REACT_APP_LOTTERY,
    [ChainId.AMOY]: '0x040363FB8b43112A692f0B668770cb3B64df6453',
  },
  multiCall: {
    [ChainId.SEPOLIA]: '0x24CC186F2F762Ec41f40b0Fea9297B9D3283b5c3',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_MULTICALL,
    // [ChainId.BSC]: process.env.REACT_APP_MULTICALL,
    [ChainId.BSC_TESTNET]: '0x57EFd519950d1906C21bef193a88c396A144Cb00',
    // [ChainId.POLYGON]: process.env.REACT_APP_MULTICALL,
    [ChainId.AMOY]: '0x6B3a27944A73cB7a8a12aA6C45d196732e1E3543',
  },
  pancakeProfile: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  pancakeRabbits: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  bunnyFactory: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  claimRefund: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  pointCenterIfo: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  bunnySpecial: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  tradingCompetition: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  easterNft: {
    [ChainId.SEPOLIA]: '',
    // [ChainId.ETHEREUM]: "",
    // [ChainId.BSC]: "",
    [ChainId.BSC_TESTNET]: '',
    // [ChainId.POLYGON]: "",
    [ChainId.AMOY]: "",
  },
  cakeVault: {
    [ChainId.SEPOLIA]: '0x74da599Afd5333256b8DFfc518321229379f11aB',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CAKEFAULT,
    // [ChainId.BSC]: process.env.REACT_APP_CAKEFAULT,
    [ChainId.BSC_TESTNET]: '0x75B7c1Dc07C6bF11053F8D1521f5547BcC6Cd242',
    // [ChainId.POLYGON]: process.env.REACT_APP_CAKEFAULT,
    [ChainId.AMOY]: '0x6378c109eE2240BB63f595fFa331a66149b6ae2b',
  },
  predictions: {
    [ChainId.SEPOLIA]: '0x8d35bEB80d08B2AB749aB306E18B9825b9470d02',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PREDICTIONS,
    // [ChainId.BSC]: process.env.REACT_APP_PREDICTIONS,
    [ChainId.BSC_TESTNET]: '0x6edd85631D07281f9f15344B89615931b793B80A',
    // [ChainId.POLYGON]: process.env.REACT_APP_PREDICTIONS,
    [ChainId.AMOY]: '0xb4721144b5285Cb0228D0A8a2f222911A2a24297',
  },
  chainlinkOracle: {
    [ChainId.SEPOLIA]: '0x88a23C9420613D9BCC03eCe2fF39C1bA4210a4d1',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CHAINLINKORACLE,
    // [ChainId.BSC]: process.env.REACT_APP_CHAINLINKORACLE,
    [ChainId.BSC_TESTNET]: '0xcb51673aFb0d88d1B1C6cF963732Fce17e497FE7',
    // [ChainId.POLYGON]: process.env.REACT_APP_CHAINLINKORACLE,
    [ChainId.AMOY]: '0xA8CF5967c9B85B3Ec4EB461991b60F13A14BD284',
  },
  bunnySpecialCakeVault: {
    [ChainId.SEPOLIA]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.BSC]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    [ChainId.BSC_TESTNET]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    // [ChainId.POLYGON]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
    [ChainId.AMOY]: process.env.REACT_APP_BUNNYSPECIALCAKEFAULT,
  },
  bunnySpecialPrediction: {
    [ChainId.SEPOLIA]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.BSC]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    [ChainId.BSC_TESTNET]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    // [ChainId.POLYGON]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
    [ChainId.AMOY]: process.env.REACT_APP_BUNNYSPECIALPREDICTION,
  },

  // tokens

  weth: {
    [ChainId.SEPOLIA]: '0xFcecf60Fd3954262a80a4460d0389010B8649f00',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_WETH,
    // [ChainId.BSC]: process.env.REACT_APP_WETH,
    [ChainId.BSC_TESTNET]: '0xeDC899e05f8709C56A744eC52Da06B609fda9cEC',
    // [ChainId.POLYGON]: process.env.REACT_APP_WETH,
    [ChainId.AMOY]: '0x22640cE04689a0B7ca9A8310c1cAd4C27102678f',
  },
  busd: {
    [ChainId.SEPOLIA]: '0x88d081131287162e9394EBC957F01E2C75CC98Bd',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUSD,
    // [ChainId.BSC]: process.env.REACT_APP_BUSD,
    [ChainId.BSC_TESTNET]: '0xee314c9BB171380De301bAc2d80c25b97DD11747',
    // [ChainId.POLYGON]: process.env.REACT_APP_BUSD,
    [ChainId.AMOY]: '0x78c1c5737984C0d71242E9b807Bb42867dB4F904',
  },
  pcn: {
    [ChainId.SEPOLIA]: '0x26cf332054007d1E5105285Cb5202257186a2CdF',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PCN,
    // [ChainId.BSC]: process.env.REACT_APP_PCN,
    [ChainId.BSC_TESTNET]: '0xB18FBf384Bb2EFBd2A2166CE4cEE682D3Db812c3',
    // [ChainId.POLYGON]: process.env.REACT_APP_PCN,
    [ChainId.AMOY]: '0x05071699842a97faFA22648820798e21BD9aa914',
  },
  corn: {
    [ChainId.SEPOLIA]: '0xD71E6eBe80590b3e78b1248abF46FA24891AeA34',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_CORN,
    // [ChainId.BSC]: process.env.REACT_APP_CORN,
    [ChainId.BSC_TESTNET]: '0x2886c7c4196f41FB884c7B42d6C29EF7AF688F35',
    // [ChainId.POLYGON]: process.env.REACT_APP_CORN,
    [ChainId.AMOY]: '0xE3993E8eA4b0370F877288aFeb985bBc3EfCE3fD',
  },
  PcnLp: {
    [ChainId.SEPOLIA]: '0xf5Aa62278521e9a6ba5208C4d55CB08DF21B392F',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_PCNLP,
    // [ChainId.BSC]: process.env.REACT_APP_PCNLP,
    [ChainId.BSC_TESTNET]: '0x4AA1Ad956f14884777BCDf211BaF1Ec1BD533177',
    // [ChainId.POLYGON]: process.env.REACT_APP_PCNLP,
    [ChainId.AMOY]: '0xe34A0a2270fE697f995E9C1a2969CB7B6e888e1e',
  },
  BusdLp: {
    [ChainId.SEPOLIA]: '0xcdBAF69fD818d046B1090899B630bA6A944B2a74',
    // [ChainId.ETHEREUM]: process.env.REACT_APP_BUSDLP,
    // [ChainId.BSC]: process.env.REACT_APP_BUSDLP,
    [ChainId.BSC_TESTNET]: '0x6B2B81790525e9A86eC292B2bf4d2BcBd03f8Cc4',
    // [ChainId.POLYGON]: process.env.REACT_APP_BUSDLP,
    [ChainId.AMOY]: '0x9f5e705eA0d067a763efEeC529133787AfaE5Ba4',
  },
}
