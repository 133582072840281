import React from 'react'
import styled from 'styled-components'
import { Flex, Heading, Text } from '@pancakeswap/uikit'
import SubNav from 'components/Menu/SubNav'

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  padding-bottom: 0;
  min-height: calc(100vh - 84px);

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    min-height: calc(100vh - 84px);
  }
`

const Page: React.FC<any> = ({
  title,
  subtitle,
  children,
}: {
  title: string
  subtitle: string
  children: React.ReactNode
}) => {
  return (
    <StyledPage>
      <div style={{ width: '100%', padding: '1rem' }}>
        <Heading as="h2" style={{ fontSize: '28px' }}>
          {title}
        </Heading>
        <Text fontSize="14px">{subtitle}</Text>
      </div>
      <SubNav />
      {children}
      <Flex flexGrow={1} />
    </StyledPage>
  )
}

export default Page
