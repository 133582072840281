import { ChainId } from '@pancakeswap/sdk'

export default {
  rpc: {
    [ChainId.SEPOLIA]: 'https://ethereum-sepolia-rpc.publicnode.com',
    // [ChainId.ETHEREUM]: "https://ethereum.blockpi.network/v1/rpc/public",
    // [ChainId.BSC]: "https://bsc-dataseed.bnbchain.org",
    [ChainId.BSC_TESTNET]: 'https://bsc-testnet.public.blastapi.io',
    // [ChainId.POLYGON]: process.env.REACT_APP_FACTORY,
    [ChainId.AMOY]: 'https://polygon-amoy.drpc.org',
  }
}
